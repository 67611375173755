const comics = [
  { url: require('@/assets/lesson1/scene5_1.svg') },
]

export default {
  name: 'step2',
  setup(props, ctx) {
    const { $router } = ctx.root

    const nextStep = () => {
      $router.push('/education2/lesson3')
    }

    return {
      comics,
      nextStep,
    }
  },
}
