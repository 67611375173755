import { ref, computed } from '@vue/composition-api'

import { pushPopup } from '@/utils/pushPopup'

import { popupsArr, popupsArr2, popupsArr3 } from './arrs'

export default {
  name: 'step1',

  setup(props, { root: { $router } }) {
    const active = ref(true)
    const popups = ref([])
    const wrapperMode = ref(false)
    const inputNum = ref('_')
    const start = ref(false)

    const lengthDisabledPopup = ref(0)
    const consoleNumItems = ref([])

    const container = ref(false)

    const scrollTop = ref(0)

    const steps = ref({
      errorFound5: false,
      errorFound2: false,
    })

    const nextStep = () => {
      if (!steps.value.errorFound5 || !steps.value.errorFound2) {
        pushPopup(popupsArr2, popups.value)
      } else {
        pushPopup(popupsArr3, popups.value)
      }
    }

    const init = async () => {
      await pushPopup(popupsArr, popups.value)
      start.value = true
    }
    init()


    const checkList = () => {
      if (inputNum.value < 7) {
        if (inputNum.value === '2') {
          consoleNumItems.value.push({
            text: 'void Trojan-ATM.Win32.JUN::run()',
            active: true,
            num: inputNum.value,
          })
          steps.value.errorFound2 = true
          nextStep()
        } else if (inputNum.value === '5') {
          consoleNumItems.value.push({
            text: 'void Trojan-ATM.Win32.Oter::check_valid()',
            active: true,
            num: inputNum.value,
          })
          steps.value.errorFound5 = true
          nextStep()
        } else {
          consoleNumItems.value.push({ active: false, num: inputNum.value })
        }
      }
      setTimeout(() => {
        scrollTop.value += 500
      }, 40)
    }

    const nextCb = () => $router.push('/education2/lesson3')

    const activeConsole = computed(() => steps.value.errorFound5 && steps.value.errorFound2)

    return {
      active,
      popups,
      wrapperMode,
      inputNum,
      lengthDisabledPopup,
      consoleNumItems,
      scrollTop,
      activeConsole,
      container,
      steps,
      start,
      nextStep,
      checkList,
      nextCb,
    }
  },
}
