import { ref } from '@vue/composition-api'

import { pushPopup } from '@/utils/pushPopup'
import { compareCommand } from '@/utils/compareCommand.js'

import {
  popupsArr0, popupsArrError, code3,
} from './arrs'

export default {
  name: 'step0',
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const active = ref(true)
    const wrapperMode = ref(false)
    const popups = ref([])
    const start = ref(false)

    const consoleInput = ref('')

    const init = async () => {
      await pushPopup(popupsArr0, popups.value)
      start.value = true
    }
    init()

    const nextStep = () => {
      active.value = false
      wrapperMode.value = true
      setTimeout(() => {
        emit('nextStep', 1)
      }, 800)
    }

    const check = () => {
      if (compareCommand(consoleInput.value, 'decompile Webserver.exe')) {
        nextStep()
      } else {
        pushPopup(popupsArrError, popups.value)
      }
    }

    return {
      active,
      popups,
      wrapperMode,
      consoleInput,
      start,
      code3,
      bg: require('./img/bg.png'),
      nextStep,
      check,
    }
  },
}
