export const popupsArr0 = [
  { text: 'Для просмотра исходного кода программы, её необходимо декомпилировать (декомпиляция позволяет показать исходный код программы на языке программирования).' },
  { text: 'Сделай это при помощи команды', buttonTextTitle: 'decompile WebServer.exe', key: 'btnArr1' },
]

export const popupsArrError = [
  { text: 'Такой команды не существует! Проверь правильность написания команды.' },
]

export const code3 = `10.01.2021 10:43              64   DriverExtensions.exe
<span style="color: red;">12.01.2022 08:34              35   WebServer.exe (Suspicious)</span>
12.01.2021 03:40              128  Application Support.exe
12.01.2021 20:00              64   ColorSync.exe`
